<template>
  <div class="resume-wrapper">
    <Transition>
      <router-link v-if="showNavbar" to="/#experience">
        <img class="return-arrow" :src="returnIcon" />
      </router-link>
    </Transition>
    <Transition>
      <img
        v-show="showNavbar"
        class="print-icon"
        :src="printerIcon"
        @click="print"
      />
    </Transition>

    <div class="page" size="A4">
      <resume-header />

      <section>
        <h1>Objective</h1>

        <p class="objective">{{ resume.objective }}</p>
      </section>

      <section>
        <h1>Traits</h1>

        <ul class="traits">
          <li v-for="trait in resume.traits" :key="skill">
            <b>{{ trait.name }}</b>
            <p class="description">{{ trait.description }}</p>
        </li>
        </ul>
      </section>

      <section>
        <h1>Experiences</h1>

        <ul>
          <resume-experience
            :key="i"
            v-for="(experience, i) in experiences"
            :experience="experience"
            :lastItem="i === experiences.length - 1"
          />
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResumeHeader from "../components/Resume/resume-header";
import ResumeExperience from "../components/Resume/resume-experience";
import returnIcon from "../assets/img/return.svg";
import printerIcon from "../assets/img/printer.svg";

export default {
  components: {
    "resume-header": ResumeHeader,
    "resume-experience": ResumeExperience,
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  data() {
    return {
      returnIcon,
      printerIcon,
      showNavbar: true,
    };
  },
  computed: {
    ...mapState({ resume: "resume", experiences: "resumeExperiences" }),
  },
  methods: {
    print() {
      window.print();
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      // Here we determine whether we need to show or hide the navbar
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.7s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.return-arrow {
  height: 40px;
  position: fixed;
  top: 40px;
  left: 40px;
}

.print-icon {
  height: 42px;
  position: fixed;
  top: 48px;
  right: 40px;
  cursor: pointer;
}

.resume-wrapper {
  background-color: #f3f3f3;
  font-family: "Roboto", sans-serif;
  padding: 0.5in 0;
}

.resume-wrapper .page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.1);
}

.resume-wrapper .page[size="A4"] {
  width: 21cm;
  /* height: 29.7cm;  */
  padding: 0.5in 0.75in;
}

.resume-wrapper h1 {
  font-size: 15pt;
  color: #33cc99;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.8pt;
}

.resume-wrapper h2 {
  font-weight: 300;
  color: #808284;
  font-size: 12pt;
  margin: 0;
}

.resume-wrapper h3 {
  font-weight: 400;
  text-transform: uppercase;
  color: #58595b;
  font-size: 11pt;
  margin: 6pt 0;
  letter-spacing: 0.5pt;
}

.resume-wrapper p,
.resume-wrapper ul {
  color: #808284;
  font-size: 10pt;
  font-weight: 300;
  line-height: 12pt;
  letter-spacing: 0.4pt;
}

.resume-wrapper .traits {
  columns: 1;
}

.resume-wrapper .traits .description {
  margin: 0.2em 0 0.8em 1em
}

.resume-wrapper .objective {
  margin: 4mm 9mm;
}

.resume-wrapper section {
  margin: 20pt 0;
}

@media print {
  .resume-wrapper,
  .resume-wrapper .page {
    margin: 0;
    padding: 0;
    box-shadow: none;
  }

  .return-arrow {
    display: none;
  }

  .print-icon {
    display: none;
  }
}
</style>
