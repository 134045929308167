<template>
  <div id="expertise" class="padded-div">
    <h2 class="hash" data-aos="fade-in">#expertise</h2>
    <h1 data-aos="fade-in">A well-rounded strategist.</h1>
    <p data-aos="fade-in">
      Coming from a
      <span class="highlight">creative-technical-leadership</span> background, I
      create value in different ways.
    </p>
    <div class="row">
      <div
        class="col-sm-12 col-md-6 col-lg-3"
        :key="exp.name"
        v-for="(exp, i) in expertise"
      >
        <expertise-card
          data-aos="fade-in"
          :data-aos-delay="i * 100"
          :expertise="exp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ExpertiseCard from "./expertise-card";
export default {
  name: "expertise",
  components: {
    "expertise-card": ExpertiseCard,
  },
  computed: mapState(["expertise"]),
};
</script>

<style scoped>
#expertise {
  background-color: rgba(51, 180, 150, 1);
  background-image: linear-gradient(#a7eedd, #33b496);
}

h1 {
  font-weight: 300;
}

p {
  font-size: 1.7em;
  line-height: 1.4em;
  color: #444444;
  margin-bottom: 40px;
}

.highlight {
  color: white;
}
</style>
