<template>
  <div class="card">
    <div class="inner-card">
      <h3>{{ expertise.name }}</h3>
      <img :src="loadImage(expertise.icon)" />
      <ul class="dimensions">
        <li :key="dimension.name" v-for="dimension in expertise.dimensions">
          {{ dimension.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "expertise-card",
  props: ["expertise"],
  methods: {
    loadImage: function(fileName) {
      return require(`../../../assets/img/expertise/${fileName}`);
    },
  },
};
</script>

<style scoped>
.card {
  margin-right: 0.8vw;
  height: 100%;
  padding-bottom: 20px;
}

.inner-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 40px 15px;
  height: 100%;
}

h3 {
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 2px;
}

.dimensions {
  margin: 0;
  padding: 0;
  font-weight: 300;
  list-style: none;
  color: #888888;
  text-align: center;
}

.dimensions li {
  line-height: 22px;
}

img {
  display: block;
  height: 60px;
  margin: 20px auto;
}
</style>
