<template>
  <ul>
    <li
      :key="tool['file-name']"
      v-for="(tool, i) of tools"
      data-aos="zoom-in"
      :data-aos-delay="getDelay(i)"
      data-aos-duration="300"
    >
      <img :src="loadImage(tool['file-name'])" />
    </li>
  </ul>
</template>

<script>
export default {
  name: "tool-list",
  props: ["tools"],
  data() {
    return {
      maxDelayIncrement: Math.floor(3000 / this.tools.length),
    };
  },
  methods: {
    loadImage: function(fileName) {
      return require(`../../assets/img/tools/${fileName}`);
    },
    getDelay(index) {
      const totalDelay = index * this.maxDelayIncrement;
      return totalDelay - (totalDelay % 50); // delay can only be in steps of 50
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

img {
  height: 2.5em;
  max-width: 4em;
  margin: 0.5em;
}
</style>
