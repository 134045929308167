<template>
  <div id="about" class="padded-div">
    <h2 class="hash" data-aos="fade-in">#about</h2>
    <h1 data-aos="fade-in">Allow me to introduce myself.</h1>
    <p data-aos="fade-in">
      My name is Lykaio Wang. I am an experienced leader in software engineering. I love <span>travelling</span> the world, building high
      <span>performance</span> teams while creating
      <span>disruptive</span> technologies.
    </p>
  </div>
</template>

<script></script>

<style scoped>
#about {
  background-color: #f3f3f3;
}

h1 {
  font-weight: 300;
}

p {
  font-size: 1.7em;
  line-height: 1.4em;
}

p > span {
  color: #1c7658;
}
</style>
