<template>
  <div class="top-menu" data-aos="flip-up">
    <span class="fa fa-bars" v-on:click="showList = !showList"></span>
    <transition name="fade">
      <ul v-if="showList">
        <li
          :key="item.id"
          v-for="item in menu"
          v-bind:class="{ highlight: highlight === item.id }"
          v-on:click="scrollTo(item.id)">
            {{item.name}}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import scroll from 'zenscroll'
export default {
  name: 'top-menu',
  data: function () {
    let menu = [
      { name: 'Hello', id: 'hello' },
      { name: 'About', id: 'about' },
      { name: 'Expertise', id: 'expertise' },
      { name: 'Tools', id: 'tools' },
      { name: 'Experience', id: 'experience' },
      { name: 'Contact', id: 'contact' }
    ]

    return {
      menu,
      showList: false,
      highlight: menu[0].id
    }
  },
  methods: {
    scrollTo (targetId) {
      this.showList = false
      let target = document.getElementById(targetId)
      var defaultDuration = 500
      var edgeOffset = 0
      this.highlight = targetId
      scroll.setup(defaultDuration, edgeOffset)
      scroll.to(target)
    }
  }
}
</script>

<style scoped>
.highlight {
  color: #164334 !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

.top-menu {
  position: fixed;
  z-index: 9999;
  font-size: 0.96em;
  background-color: #33cc99;
  border-radius: 15px;
  padding: 0.5em;
  display: flex;
  align-items: center;
}

.top-menu ul {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.4em;
  margin-right: 0em;
  padding: 0;
}

.top-menu li {
  display: inline-block;
  margin: 0 0.5em;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
  font-size: 0.8em;
  line-height: 3em;
  cursor: pointer;
}

@media (min-width: 0px) {
  .top-menu {
    left: 0.5em;
    top: 1em;
  }
}

@media (min-width: 576px) {
  .top-menu {
    left: 0.5em;
    top: 1em;
  }
}

@media (min-width: 768px) {
  .top-menu {
    left: 1.75em;
    top: 3em;
  }
}

@media (min-width: 992px) {
  .top-menu {
    left: 3em;
    top: 4em;
  }
}

@media (min-width: 1200px) {
  .top-menu {
    left: 3.5em;
    top: 6em;
  }
}


.fa-bars {
  font-size: 1.5em;
  color: white;
  margin: 0.1em 0.3em;
  cursor: pointer;
}

</style>
