import { createRouter, createWebHistory } from 'vue-router'
import Home from './pages/Home'
import Bucket from './pages/Bucket'
import Resume from './pages/Resume'

const routes = [
    { path: '/bucket', component: Bucket },
    { path: '/resume', component: Resume },
    { path: '/', component: Home }
]

export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
        return { el: to.hash }
    }

    return { top: 0 }
  },
})
