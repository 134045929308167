<template>
  <section :style="getSectionCss()">
    <span class="date">{{ date.start }} - {{ date.end }}</span>
    <h3>
      {{ position }}, <span>{{ name }}</span>
    </h3>
    <div class="logo" :style="logoStyle" />
    <ul>
      <li :key="detail" v-for="detail in details">{{ detail }}</li>
    </ul>
  </section>
</template>

<script>
export default {
  props: ["experience", "lastItem"],
  data() {
    const {
      city,
      name,
      date,
      description,
      details,
      position,
      logo,
      logoScale = 1,
    } = this.experience;
    return {
      city,
      name,
      date,
      description,
      details,
      position,
      logo,
      logoScale,
    };
  },
  methods: {
    getSectionCss() {
      return !this.lastItem
        ? {
            "border-bottom": "1px dashed #eeeeef",
            "padding-bottom": "7mm",
            "margin-bottom": "7mm",
          }
        : {
            margin: 0,
          };
    },
  },
  computed: {
    companyLogo() {
      const images = require.context(
        "../../assets/img/companies-resume",
        false
      );
      return images("./" + this.experience["resume-logo"]);
    },
    logoStyle() {
      return {
        backgroundImage: `url(${this.companyLogo})`,
        width: `${25 * this.logoScale}mm`,
        height: `${10 * this.logoScale}mm`,
      };
    },
  },
};
</script>

<style scoped>
.logo {
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
  margin: 4mm 0 0 0mm;
}

.date {
  float: right;
  color: #58595b;
  font-weight: 400;
}

h3 {
  clear: left;
}

h3 span {
  font-weight: 300;
  text-transform: none;
}

ul {
  margin: 15pt 0 0 90pt;
}

li {
  margin-bottom: 4pt;
  line-height: 11.5pt;
}
</style>
