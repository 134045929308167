<template>
  <div id="contact" class="padded-div">
    <h2 data-aos="fade-in" class="hash">#contact</h2>
    <div class="contacts">
      <a
        :key="contact.link"
        v-for="contact in contacts"
        :href="contact.link"
        data-aos="fade-in"
        target="_blank"
      >
        <i
          class="contact"
          :style="{ color: contact.color }"
          :class="'fa ' + contact.icon"
        ></i>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "contact",
  computed: {
    ...mapState(["contacts"]),
  },
};
</script>

<style scoped>
#contact {
  background-color: black;
}

#contact .hash {
  color: white;
}

.contacts {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4em;
}

.contact {
  font-size: 3em;
  cursor: pointer;
  margin: 0 0.8em;
  filter: grayscale(1);
  transition: 0.5s filter;
}

.contact:hover {
  filter: grayscale(0);
}

.padded-div {
  background-color: #f3f3f3;
}
</style>
