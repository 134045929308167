<template>
  <div id="experience" class="padded-div">
    <h2 class="hash" data-aos="fade-in">#experience</h2>
    <h1 data-aos="fade-in">
      {{ devYears }} years of engineering, and counting...
    </h1>
    <div class="row">
      <div
        class="col-sm-12 col-md-6"
        :key="exp.company"
        v-for="(exp, i) in experiences"
      >
        <cell
          v-bind:data="exp"
          data-aos="slide-up"
          data-aos-duration="900"
          :data-aos-offset="i % 2 === 0 ? 0 : 200"
        />
      </div>
    </div>

    <router-link class="view-resume" data-aos="fade-in" to="/resume">
      <img :src="resumeIcon" />View My Full Resume
    </router-link>
  </div>
</template>

<script>
import ExperienceCell from "./experience-cell";
import resumeIcon from "../../../assets/img/resume.svg";
import { mapState } from "vuex";

export default {
  name: "experience",
  components: {
    cell: ExperienceCell,
  },
  data: () => {
    return {
      resumeIcon,
    };
  },
  computed: {
    ...mapState({ experiences: "resumeExperiences" }),
    devYears() {
      const currentYear = new Date().getFullYear();
      return currentYear - 2012;
    },
  },
};
</script>

<style scoped>
#experience {
  background-color: #f3f3f3;
}

h1 {
  font-weight: 300;
}

p {
  font-size: 1.7em;
}

.view-resume {
  color: black;
  display: block;
  margin-top: 6em;
  text-align: center;
  font-size: 16px;
}

.view-resume img {
  height: 20px;
  display: inline-block;
  margin: 0 8px;
  transform: translateY(4px);
}

.view-resume i {
  display: inline-block;
  margin-right: 0.2em;
}
</style>
