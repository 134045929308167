<template>
  <div class="bucket-list">
    <img class="bucket-icon" :src="bucketIcon" />
    <p>Better do it all, before the bucket fall.</p>
    <category v-for="(category, i) in bucketList" :category="category" :key="category.name" :alternateStyle="i % 2 == 0"/>
  </div>
</template>

<script>
import bucketList from '../data/bucket.json'
import BucketListCategory from '../components/bucket-list-category'
import bucketIcon from '../assets/img/bucket.svg'

export default {
  name: 'experience-cell-date',
  components: {
    category: BucketListCategory
  },
  data () {
    return { bucketList, bucketIcon }
  }
}
</script>

<style scoped>
.bucket-list {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.bucket-icon {
  height: 100px;
}
</style>
