<template>
  <div id="companies" class="padded-div">
    <h2 class="hash" data-aos="fade-in">#companies</h2>
    <h1 data-aos="fade-in">A proven track record.</h1>
    <p data-aos="fade-in">
      I have been privileged to work with a diversity of companies and
      organizations.
    </p>
    <ul>
      <li
        :class="company.url ? 'has-url' : ''"
        :key="company.name"
        v-for="(company, i) of companies"
        data-aos="zoom-in"
        :data-aos-delay="i * 50"
        data-aos-duration="300"
      >
        <img
          :src="loadImage(company['logo'])"
          @click="openInNewPage(company.url)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({ companies: "experiences" }),
  },
  methods: {
    loadImage: function(fileName) {
      return require(`../../assets/img/companies/${fileName}`);
    },
    openInNewPage: function(url) {
      if (!url) return;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
p {
  font-size: 1.7em;
}

h1 {
  font-weight: 300;
}

ul {
  transform: translateX(-2em);
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

img {
  max-width: 10em;
  margin: -1.3em 0.5em;
  filter: opacity(0.8);
  transition: all 0.3s;
}

img:hover {
  filter: opacity(1);
}

.has-url {
  cursor: pointer;
}
</style>
