<template>
  <div class="bucket-category">
    <h1>{{category.name}}</h1>
    <p>{{category.quote}}</p>
    <ul>
      <li v-for="item in category.items" :key="item.name" :class="item.done ? 'done' : ''">{{item.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'bucket-list-category',
  props: ['category']
}
</script>

<style scoped>
.bucket-category {
  background-color: #fcfcfc;
  width: 60vw;
  margin: 15px 0;
  padding: 25px 20px;
  border-radius: 5px;
  -webkit-box-shadow: 3px 4px 10px -1px rgba(0,0,0,0.15);
  -moz-box-shadow: 3px 4px 10px -1px rgba(0,0,0,0.15);
  box-shadow: 3px 4px 10px -1px rgba(0,0,0,0.15);
}

@media only screen and (max-width: 1200px) {
  .bucket-category {
    width: 80vw;
  }
}


@media only screen and (max-width: 600px) {
  .bucket-category {
    width: 90vw;
  }
}

h1 {
  margin: 0;
  font-size: 24px;
}

ul {
  margin: 0;
}

.done {
  text-decoration: line-through;
}
</style>
