<template>
  <div id="tools" class="padded-div">
    <h2 class="hash" data-aos="fade-in">#tools</h2>
    <h1 data-aos="fade-in">
      My
      <span class="bracket">[</span>
      array
      <span class="bracket">]</span>
      of tools.
    </h1>
    <p data-aos="fade-in">
      Here are some of my favorite tools I use to build software at scale.
    </p>
    <tool-list :tools="tools" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToolList from "./tool-list";
export default {
  name: "tools",
  components: {
    "tool-list": ToolList,
  },
  computed: mapState(["tools"]),
};
</script>

<style scoped>
.bracket {
  animation: bracket 8s infinite;
}

@keyframes bracket {
  0% {
    color: #41ce97;
    opacity: 1;
    margin: 0;
  }
  13% {
    opacity: 0.4;
    margin: 0 2px;
  }
  25% {
    color: white;
    opacity: 1;
    margin: 0;
  }
  38% {
    opacity: 0.4;
    margin: 0 2px;
  }
  50% {
    color: #41ce97;
    opacity: 1;
    margin: 0;
  }
  63% {
    opacity: 0.4;
    margin: 0 2px;
  }
  75% {
    color: white;
    opacity: 1;
    margin: 0;
  }
  88% {
    opacity: 0.4;
    margin: 0 2px;
  }
  100% {
    color: #41ce97;
    opacity: 1;
    margin: 0;
  }
}

#tools {
  background-color: #070d0c;
}

.hash {
  color: white;
}

h1 {
  font-weight: 300;
  color: white;
}

p {
  font-size: 1.7em;
}
</style>
