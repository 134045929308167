<template>
  <span>{{this.date.start}} - {{this.date.end}}</span>
</template>

<script>
export default {
  name: 'experience-cell-date',
  props: ['date']
}
</script>

<style scoped>
span {
  background-color: #33cc99;
  color: white;
  font-size: 0.8em;
  margin-left: 0.7em;
  padding: 0.4em 0.9em;
  border-radius: 6em;
  text-align: center;
  white-space: nowrap;
}
</style>
