import { createStore } from "vuex";

import resume from "../data/resume.json";
import contacts from "../data/contacts.json";
import tools from "../data/tools.json";
import expertise from "../data/expertise.json";

const store = createStore({
  state() {
    const { experiences } = resume;

    return {
      resume,
      experiences,
      resumeExperiences: experiences.filter(
        (exp) => exp.showOnResume !== false
      ),
      contacts,
      tools,
      expertise,
    };
  },
});

export default store;
