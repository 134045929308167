<template>
  <div class="experience-cell">
    <div class="title">
      <h1>{{ data.position }}</h1>
      <cell-date v-bind:date="data.date" style="float:right" />
    </div>
    <h2>{{ data.name }}</h2>
    <p>
      {{ data.description }}
    </p>
  </div>
</template>

<script>
import CellDate from "./experience-cell-date";
export default {
  name: "experience-cell",
  components: {
    "cell-date": CellDate,
  },
  props: ["data"],
};
</script>

<style scoped>
.experience-cell {
  margin: 2em 0;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h1 {
  font-size: 1.4em;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0.15em;
}

h2 {
  font-weight: 300;
  font-size: 1em;
  margin: 0;
  font-style: italic;
}

p {
  text-align: justify;
}
</style>
