<template>
  <div>
    <top-menu />
    <hello />
    <about />
    <expertise />
    <companies />
    <Tools />
    <experience />
    <contact />
  </div>
</template>

<script>
import TopMenu from "../components/Home/top-menu";
import Hello from "../components/Home/hello";
import About from "../components/Home/about";
import Expertise from "../components/Home/Expertise";
import Experience from "../components/Home/Experience";
import Tools from "../components/Home/tools";
import Contact from "../components/Home/contact";
import Companies from "../components/Home/companies";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "app",
  components: {
    "top-menu": TopMenu,
    hello: Hello,
    about: About,
    experience: Experience,
    Tools,
    contact: Contact,
    companies: Companies,
    expertise: Expertise,
  },
  mounted() {
    AOS.init({
      duration: 1000,
      once: true,
    });
  },
};
</script>

<style>
@import url("../assets/css/bootstrap-grid.min.css");
@import url("../assets/css/global.css");
@import url("../assets/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");

body {
  overflow-x: hidden;
}
</style>
